import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from 'bootstrap/dist/js/bootstrap.esm.min.js';

import { Parallax } from 'react-parallax'
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";

import style from "./style.module.css"
import classNames from "classnames";

import aboutImage from "../../assets/about.jpg"
import manImage from "../../assets/man.png"

import api from "../../service/api";
import milestonesImage from '../../assets/milestones.jpg'
import CountUp from "react-countup"

import { faR, faClock, faVideo, faSearch, faChevronDown, faAngleDoubleRight, faCode, faBrain, faGears, faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'

//Team pictures 
import renatoPanda from "../../assets/RenatoPanda.jpg"
import helderRenato from "../../assets/HelderRenato.jpg"
import tiagoantonio from "../../assets/TiagoAntonio.jpg"
import ricardoantonio from "../../assets/RicardoAntonio.jpg"
import pedrolouro from "../../assets/PedroLouro.jpg"
import joaoCanoso from "../../assets/JoaoCanoso.jpg"
import hugoredinho from "../../assets/HugoRedinho.jpg"
import tiagoareias from "../../assets/TiagoAreias.jpg"
export default function About() {
    const [streamedminutes, setStreamedMinutes] = useState(0)
    const [analysedVideos, setAnalysedVideos] = useState(0)

    async function getStreamedMinutes() {
        const response = await api.get("/song/get/streamedminutes")
            .then(message => {
                setStreamedMinutes(message.data)
            }).catch(error => {
                if (error.response) {
                    console.log(error.request.data)
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error);
                }
            })
    }


    async function getAnalysedVideos() {
        const response = await api.get("/song/get/analysed/videos")
            .then(message => {
                setAnalysedVideos(message.data)
            }).catch(error => {
                if (error.response) {
                    console.log(error.request.data)
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error);
                }
            })
    }

    async function addPopoverElements() {
        Array.from(document.querySelectorAll('button[data-bs-toggle="popover"]'))
            .forEach(popoverNode => new Popover(popoverNode, {
                trigger: 'focus'
            }))

    }

    useEffect(() => {
        getAnalysedVideos()
        getStreamedMinutes()
        addPopoverElements()
    }, [])
    return (
        <>
            {/*Loading Bar*/}
            <div className="super_container">
                <NavBar active={4}></NavBar>
                <Parallax className="parallax_background parallax-window" speed={0.8} bgImage={aboutImage}>
                    <div className={style.home}>
                        <div className={classNames(style.home_container, "d-flex flex-column align-items-center justify-content-center")}>
                            <div className={style.home_content}>
                                <div className={style.home_title}>
                                    <h1>about</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </Parallax>
                <div className={style.about}>
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className={classNames(style.about_container, "d-flex flex-lg-row flex-column align-items-start justify-content-start")}>
                                    <div className={style.about_content}>
                                        <div className={style.section_title}>
                                            <h1>About MERmaid</h1>
                                        </div>
                                        <div className={style.about_text}>
                                            <p>MERmaid is a Music Emotion Recognition (MER) application from the <a href="http://mir.dei.uc.pt/" target="_blank">MIR.CISUC team</a>, part of the Music Emotion Recognition - Next Generation (<a href="https://doi.org/10.54499/PTDC/CCI-COM/3171/2021" target="_blank">PTDC/CCI-COM/3171/2021</a>) research project. The system has been developed over the last years in a collaboration between <a href="https://www.cisuc.uc.pt/" target="_blank">CISUC</a> and <a href="http://ci2.ipt.pt/" target="_blank">Ci2.ipt</a>, with the purpose of illustrating the advances on this research topic, which usually stay buried in academic papers and do not reach the general public.
                                                The application is a complex set of services fusing audio (raw signals), lyrics (text), source separation, signal segmentation, machine learning and other topics. Built as containerized microservices (docker) communicating via message brokers, orchestrated inside the CISUC infrastructure.</p>
                                        </div>
                                    </div>
                                    <div className={style.about_image}><img src={manImage} alt="" /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Parallax className="parallax_background parallax-window" bgImage={milestonesImage} speed={0.8}>
                    <div className={classNames("container", style.milestones)}>
                        <div className="row">
                            <div className="col">
                                <div className={classNames(style.section_title, style.light, "text-center")}>
                                    <h1>Fun Facts</h1>
                                </div>
                            </div>
                        </div>
                        <div className={classNames("row", style.milestones_row)}>
                            <div className="col-xl-6 col-md-12">
                                <div className={classNames(style.milestone, "text-center")}>
                                    <div className={classNames(style.milestone_content, "ml-auto mr-auto")}>
                                        <CountUp className={style.milestone_counter} end={streamedminutes}></CountUp>
                                        <div className={style.milestone_icon}><FontAwesomeIcon icon={faClock} size="6x" /></div>
                                    </div>
                                    <div className={style.milestone_title}>Streamed Minutes</div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-12">
                                <div className={classNames(style.milestone, "text-center")}>
                                    <div className={classNames(style.milestone_content, "ml-auto mr-auto")}>
                                        <CountUp className={style.milestone_counter} end={analysedVideos}></CountUp>
                                        <div className={style.milestone_icon}><FontAwesomeIcon icon={faVideo} size="6x" /></div>
                                    </div>
                                    <div className={style.milestone_title}>Analyzed Videos</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Parallax>

                <div className={style.about}>
                    <div className="container">

                        <div className="row">
                            <div className="col">
                                <div className={style.guests}>
                                    <div className={style.section_title}><h1>Team</h1></div>

                                    <div className={classNames(style.guests_container, "d-flex flex-md-row flex-column align-items-md-start align-items-center justify-content-start")}>

                                        <div className={style.guest_container}>
                                            <div className={style.guest}>
                                                <div className={style.guest_image}><img src={renatoPanda} /></div>
                                                <div className={classNames(style.guest_content, "text-center")}>
                                                    <div className={style.guest_name}><a href="https://renatopanda.github.io/" target="_blank">Renato Panda</a></div>
                                                    <div className={style.guest_title}>
                                                        <button type="button" className={style.guest_title}
                                                            data-bs-toggle="popover" data-bs-placement="bottom"
                                                            data-bs-custom-class="custom-popover"
                                                            data-bs-title="Renato Panda's Contributions"
                                                            data-bs-content="CISUC and Ci2 researcher. Project coordinator and advisor of all the students that contributed sofar.">
                                                            <FontAwesomeIcon icon={faBrain} /> Project Maestro <FontAwesomeIcon icon={faCircleQuestion} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={style.guest_container}>
                                            <div className={style.guest}>
                                                <div className={style.guest_image}><img src={helderRenato} /></div>
                                                <div className={classNames(style.guest_content, "text-center")}>
                                                    <div className={style.guest_name}><a href="https://helderrenato.github.io/Portfolio/" target="_blank">Hélder Ribeiro</a></div>
                                                    <div className={style.guest_title}>
                                                        <button type="button" className={style.guest_title}
                                                            data-bs-toggle="popover" data-bs-placement="bottom"
                                                            data-bs-custom-class="custom-popover"
                                                            data-bs-title="Hélder Ribeiro's Contributions"
                                                            data-bs-content="BSc student @ ipt.pt. Implemented the current MERmaid web app (FE + API) that interacts with the MER services.">
                                                            <FontAwesomeIcon icon={faGears} /> Web Developer <FontAwesomeIcon icon={faCircleQuestion} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={style.guest_container}>
                                            <div className={style.guest}>
                                                <div className={style.guest_image}><img src={hugoredinho} /></div>
                                                <div className={classNames(style.guest_content, "text-center")}>
                                                    <div className={style.guest_name}><a href="#" >Hugo Redinho</a></div>
                                                    <div className={style.guest_title}>
                                                        <button type="button" className={style.guest_title}
                                                            data-bs-toggle="popover" data-bs-placement="bottom"
                                                            data-bs-custom-class="custom-popover"
                                                            data-bs-title="Hugo Redinho's Contributions"
                                                            data-bs-content="Former MSc student @ dei.uc. Developed the Lyrics feature extraction engine and is a contributor to the MERGE project.">
                                                            <FontAwesomeIcon icon={faGraduationCap} /> ML Researcher <FontAwesomeIcon icon={faCircleQuestion} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={style.guest_container}>
                                            <div className={style.guest}>
                                                <div className={style.guest_image}><img src={pedrolouro} /></div>
                                                <div className={classNames(style.guest_content, "text-center")}>
                                                    <div className={style.guest_name}><a href="#" >Pedro Louro</a></div>
                                                    <div className={style.guest_title}>
                                                        <button type="button" className={style.guest_title}
                                                            data-bs-toggle="popover" data-bs-placement="bottom"
                                                            data-bs-custom-class="custom-popover"
                                                            data-bs-title="Pedro Louro's Contributions"
                                                            data-bs-content="PhD student @ dei.uc. Currently helping with the MER classification blocks, Pedro is part of the MERGE project as a PhD student, where he plans to tackle audio MER by proposing novel features and exploring Deep Learning approaches.">
                                                            <FontAwesomeIcon icon={faGraduationCap} /> ML Researcher <FontAwesomeIcon icon={faCircleQuestion} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className={style.guests}>
                                    <div className={style.section_title}><h3>Former Contributors</h3></div>

                                    <div className={classNames(style.guests_container, "d-flex flex-md-row flex-column align-items-md-start align-items-center justify-content-start")}>

                                        <div className={style.guest_container}>
                                            <div className={style.guest}>
                                                <div className={style.guest_image}><img src={joaoCanoso} /></div>
                                                <div className={classNames(style.guest_content, "text-center")}>
                                                    <div className={style.guest_name}><a href="#" >João Canoso</a></div>
                                                    <div>
                                                        <button type="button" className={style.guest_title}
                                                            data-bs-toggle="popover" data-bs-placement="bottom"
                                                            data-bs-custom-class="custom-popover"
                                                            data-bs-title="João Canoso's Contributions"
                                                            data-bs-content="Former MSc student @ipt.pt, João was one of the key elements to the project. Defined the orchestration and infrastructure parts, containerized multiple services, adapted the UI template, and many more tasks.">
                                                            <FontAwesomeIcon icon={faGears} /> DevOps Architect <FontAwesomeIcon icon={faCircleQuestion} />
                                                        </button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>

                                        <div className={style.guest_container}>
                                            <div className={style.guest}>
                                                <div className={style.guest_image}><img src={ricardoantonio} /></div>
                                                <div className={classNames(style.guest_content, "text-center")}>
                                                    <div className={style.guest_name}><a href="#" >Ricardo António</a></div>
                                                    <div className={style.guest_title}>
                                                        <button type="button" className={style.guest_title}
                                                            data-bs-toggle="popover" data-bs-placement="bottom"
                                                            data-bs-custom-class="custom-popover"
                                                            data-bs-title="Ricardo's Contributions"
                                                            data-bs-content="Former MSc student @ipt.pt, the first student to accept the challenge, developed the initial proof-of-concept MER system using a microservices approach.">
                                                            <FontAwesomeIcon icon={faCode} /> Software Engineer<FontAwesomeIcon icon={faCircleQuestion} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={style.guest_container}>
                                            <div className={style.guest}>
                                                <div className={style.guest_image}><img src={tiagoantonio} /></div>
                                                <div className={classNames(style.guest_content, "text-center")}>
                                                    <div className={style.guest_name}><a href="#" >Tiago António</a></div>
                                                    <div className={style.guest_title}>
                                                        <button type="button" className={style.guest_title}
                                                            data-bs-toggle="popover" data-bs-placement="bottom"
                                                            data-bs-custom-class="custom-popover"
                                                            data-bs-title="Tiago's Contributions"
                                                            data-bs-content="Former BSc and MSc student @ipt.pt, together with Tiago Areias, developed the first UI / FE App as a BSc project. Later pursued his MSc thesis on the same topic, focusing on the ML part of the system for audio and lyrics.">
                                                            <FontAwesomeIcon icon={faCode} /> Software Engineer<FontAwesomeIcon icon={faCircleQuestion} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={style.guest_container}>
                                            <div className={style.guest}>
                                                <div className={style.guest_image}><img src={tiagoareias} /></div>
                                                <div className={classNames(style.guest_content, "text-center")}>
                                                    <div className={style.guest_name}><a href="#" >Tiago Areias</a></div>
                                                    <div className={style.guest_title}>
                                                        <button type="button" className={style.guest_title}
                                                            data-bs-toggle="popover" data-bs-placement="bottom"
                                                            data-bs-custom-class="custom-popover"
                                                            data-bs-title="Tiago's Contributions"
                                                            data-bs-content="Former BSc student @ipt.pt, together with Tiago António, developed the initial UI / FE App used by the first proof-of-concept.">
                                                            <FontAwesomeIcon icon={faCode} /> Developer <FontAwesomeIcon icon={faCircleQuestion} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className={classNames(style.guests_container, "d-flex flex-md-row flex-column align-items-md-start align-items-center justify-content-start")}>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </>
    )
}
