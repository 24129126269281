import React, {useEffect, useState} from "react";
import style from "./style.module.css"
import logo from '../../assets/play.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import {Modal, NavDropdown} from 'react-bootstrap'
import api from "../../service/api";
import {useNavigate} from "react-router-dom"
import emailLogo from "../../assets/emailLogo.svg"
import loading from "../../assets/loading.svg"

export default function NavBar(props, {history}){
    const [scrolled, setScrolled] = useState(false)
    const turnNavBarBlack = () => setScrolled(props.scrolled)
    const [activeMenu, setActiveMenu] = useState(false)

    const [showRegister, setShowRegister] = useState(false)
    const [showLogin, setShowLogin] = useState(false)
    const [showConfirmEmail, setshowConfirmEmail] = useState(false)
    const [showLogout, setshowLogout] = useState(false)
    const [showResetPassw, setShowResetPassw] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)

    //User Input variables 
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [cpassword, setcPassword] = useState('')
    const [name, setName] = useState('')
    const [admin, setAdmin] = useState(false)
    //Token of the user 
    const [user, setUser] = useState('')
    //Track first reload of the page 
    const [needsAuth, setNeedsAuth] = useState(false)
    //navigation
    const navigation = useNavigate()
    
    const [emailReset, setEmailReset] = useState('')
    const [isLoading, setisLoading] = useState(false)
    
    async function resetPassw(event){
        event.preventDefault()
        setisLoading(true)
        var errorMessage = document.getElementById(style.errorMessage3)
        errorMessage.style.display = "none"

        if(!(cpassword === password)){
            alert("The password dont match please try again!")
            return
        }

        const response = await api.post("/user/reset/password", {email: emailReset})
        .then(message => {
            alert("Please check your email to reset your password")
        }).catch(error => {
            if (error.response) {
                errorMessage.textContent = error.response.data
                errorMessage.style.display = "block"
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
        })    

        setisLoading(false)
    }
    //Login
    async function Login(event){
        event.preventDefault()
        var errorMessage = document.getElementById(style.errorMessage2)

        errorMessage.style.display = "none"
    
        const user = {
            email: email, 
            passw: password
        }

        //Call the api and make the request to register the user
        const response = await api.post("/login", user)
        .then(message => {
            //If the user is succesfuly registered than he can be logged at the website
            localStorage.setItem("token", message.data.token)
            window.location.reload()
        }).catch(error => {
            if (error.response) {
                errorMessage.textContent = error.response.data
                errorMessage.style.display = "block"
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
        })    

    }

    //LogOut
    function LogOut(){
        localStorage.removeItem("token")
        window.location.reload()
    }

    //Register
    async function Register(event){
        setisLoading(true)
        event.preventDefault()
        var errorMessage = document.getElementById(style.errorMessage)
        var admin = false
        errorMessage.style.display = "none"
        errorMessage.classList.remove(style.sucess)
        //Verify if the passwors is equal to cpassword 
        if(isAdmin){
            admin = (document.getElementById("isAdmin").value == 2) ? true : false
        }

        if(!(password === cpassword)){
            errorMessage.textContent = "The passwords dont match"
            errorMessage.style.display = "block"
        }else{
            const user = {
                email: email, 
                passw: password, 
                name: name,
                admin: admin, 
            }
    
            //Call the api and make the request to register the user
            const response = await api.post("/signup", user)
            .then(message => {
                //If the user is succesfuly registered than he needs to confirm the email 
                handleCloseRegister()
                handleShowConfirmEmail()
            }).catch(error => {
                if (error.response) {
                    errorMessage.textContent = error.response.data
                    errorMessage.style.display = "block"
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            })    
        }  
        
        setisLoading(false)
    }

    //get user information 
    async function getUser(){
        var token = localStorage.getItem("token")

        const response = await api.get("/user", {
            headers: { Authorization: `Bearer ${token}`}
        })
            .then(message => {
                //If the user is succesfuly registered than he can be logged at the website
                setUser(message.data)
                if(message.data.admin){
                    setIsAdmin(true)
                }
            }).catch(error => {
                if (error.response) {
                    if(error.response.data.error === "Token expired!"){
                        alert("Your session has expired. Please log in again to continue using our service.")
                        LogOut()
                        navigation('/')
                    }
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            })    

        
    }

    //Activate the menu to create an animation soo as the user clicks on the hamnurguer menu icon
    function hamburguerMenu(){
        if(activeMenu){setActiveMenu(false)}else{setActiveMenu(true)}
    }
    
    function turnBlackOnScrolling(){
		if(window.scrollY > 0){setScrolled(true)}else{setScrolled(false)}
    }

    
    //Show and hidde register modal
    const handleCloseRegister = () => setShowRegister(false)
    const handleShowRegister = () => setShowRegister(true)

    //Show and hidde login modal
    const handleCloseLogin = () => setShowLogin(false)
    const handleShowLogin = () => setShowLogin(true)

    //handle confirm email modal
    const handleShowConfirmEmail = () => setshowConfirmEmail(true)
    const handleCloseConfirmEmail = () => setshowConfirmEmail(false)

    //handle logout
    const handleShowLogout = () => setshowLogout(true)
    const handleCloseLogout = () => setshowLogout(false)

    //handle reset passw
    function handleShowResetPassw(){
        handleCloseLogin()
        setShowResetPassw(true)
    }
    const handleCloseResetPassw = () => setShowResetPassw(false)

    useEffect(() => {
        if(needsAuth != props.isNotAuthenticated && props.isNotAuthenticated != undefined){
            setShowLogin(true)
            setNeedsAuth(props.isNotAuthenticated)
        }
        
        
    }, [props.isNotAuthenticated])

    //Turn the NavBar black everytime the users scrolls
    useEffect(() => {
        window.addEventListener('scroll', turnBlackOnScrolling)
        if(props.scrolled) turnNavBarBlack()
        getUser()
    }, [])


   

    return(
        <>
            <div className="top-loading-bar" id="loading-bar"></div>
            <header className = {scrolled ? classnames(style.header, "trans_400",  style.scrolled) : classnames(style.header, "trans_400")}>
                <div className={style.logo}>
                    <a href="/"><span>MER</span>maid<img src={logo} alt="" /></a>
                </div>    
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className={classnames(style.header_content, "d-flex flex-row align-items-center justify-content-start trans_400")}>
                                <nav className={style.main_nav}>
                                    <ul className="d-flex flex-row align-items-start justify-content-start">
                                        <li><a href="/" className={(props.active == 1) ? style.active : ""}>Home</a></li>
                                        <li><a href="/queue" className={(props.active == 2) ? style.active : ""}>Queue</a></li>
                                        <li><a href="/browse" className={(props.active == 3) ? style.active : ""}>Browse</a></li>
                                        <li><a href="/about" className={(props.active == 4) ? style.active : ""}>About</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    localStorage.getItem("token") ? (
                        <div className={classnames(style.header_right, "d-flex flex-row align-items-start justify-content-start")}>
                            <div className={classnames(style.submit, style.signUp)}><a onClick={handleShowLogout}>LogOut</a></div>
                            <div className={classnames("dropdown", style.userS)}>
                                <button className={classnames("btn btn-secondary dropdown-toggle", style.d)} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {user.name}
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {
                                        isAdmin ? (
                                            <>
                                             <a className="dropdown-item" onClick={handleShowRegister} href="#">Create User</a>
                                             <a className="dropdown-item" href="/manage/users">Manage Users</a>
                                            </>
                                        ) : (
                                            <>
                                                <a className="dropdown-item" href="/userprofile">View Profile</a>
                                            </>
                                        )
                                    }
                                    
                                </div>
                            </div>
                            <div className={style.hamburger} onClick={hamburguerMenu}><FontAwesomeIcon icon={faBars} aria-hidden="true" size="2x" inverse/>
                        </div>
                    </div>
                    ) : (
                        <div className={classnames(style.header_right, "d-flex flex-row align-items-start justify-content-start")}>
                            <div className={classnames(style.submit, style.signUp)}><a onClick={handleShowRegister}>SignUp</a></div>
                            <div className={style.submit}><a onClick={handleShowLogin}>Login</a></div>
                            <div className={style.hamburger} onClick={hamburguerMenu}><FontAwesomeIcon icon={faBars} aria-hidden="true" size="2x" inverse/>
                        </div>
                    </div>
                    )
                }

                
            </header>  

            <div className={activeMenu ? classnames(style.menu, style.active) : style.menu}>
			    <div className={classnames(style.menu_content, "d-flex flex-column align-items-end justify-content-start")}>
                    <ul className={classnames(style.menu_nav_list,"text-right")}>
                        <li><a href="/" className={(props.active == 1) ? style.active : ""}>Home</a></li>
                        <li><a href="/queue" className={(props.active == 2) ? style.active : ""}>Queue</a></li>
                        <li><a href="/browse" className={(props.active == 3) ? style.active : ""}>Browse</a></li>
                        <li><a href="/about" className={(props.active == 4) ? style.active : ""}>About</a></li>
                    </ul>

                    {
                        localStorage.getItem("token") ? (
                            <div className={classnames(style.menu_extra, "d-flex flex-column align-items-end justify-content-start")}>
                                <div className={style.menu_submit}><a onClick={handleShowLogout} href="#">LogOut</a></div>
                                <div className={classnames("dropdown", style.menu_submit2)}>
                                    <button className={classnames("btn btn-secondary dropdown-toggle", style.d)} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {user.name}
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {
                                        isAdmin ? (
                                            <>
                                             <a className="dropdown-item" onClick={handleShowRegister} href="#">Create User</a>
                                             <a className="dropdown-item" href="/manage/users">Manage Users</a>
                                            </>
                                        ) : (
                                            <>
                                                <a className="dropdown-item" href="/userprofile">View Profile</a>
                                            </>
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={classnames(style.menu_extra, "d-flex flex-column align-items-end justify-content-start")}>
                                <div className={style.menu_submit}><a onClick={handleShowLogin} href="#">Login</a></div>
                                <div className={style.menu_submit}><a onClick={handleShowRegister}>Register</a></div>
                            </div>
                        )
                    }

			    </div>
		    </div>

             {/*Modal Register and Login */}

             {/*-----------------------------Register------------------- */}
             <Modal show={showRegister} onHide={handleCloseRegister}>
                <Modal.Header closeButton>
                    <Modal.Title>{isAdmin ? "Create User" : "SignUp"}</Modal.Title>
                </Modal.Header >
                <Modal.Body>
                    <form className={style.signUpForm}  onSubmit={Register}>
                    <img src={loading} className={isLoading ? style.active : style.noactive }></img>
                        <div id={style.errorMessage}> 
                            
                        </div>
                        {
                            isAdmin ? (
                                <>
                                    <select class="form-select" aria-label="Default select example" id="isAdmin">
                                        <option selected value={1}>Normal User</option>
                                        <option value={2}>Admin</option>
                                    </select>
                                </>
                            ) : (
                                <></>
                            )
                        }
                        <label htmlFor="email">Name*</label>
                        <input type={"text"} placeholder="" id="name" required onChange={event => setName(event.target.value)}></input>
         

                        <label htmlFor="email">Email*</label>
                        <input type={"email"} placeholder="Enter your email..." required id="email" onChange={event => setEmail(event.target.value)}></input>
             

                        <label htmlFor="password">Password*</label>
                        <input type={"password"} id="password" required onChange={event => setPassword(event.target.value)}></input>
                     

                        <label htmlFor="Cpassword">Confirmar Password*</label>
                        <input type={"password"} id="Cpassword" required onChange={event => setcPassword(event.target.value)}></input>
                       
                        <button type="submit">{isAdmin ? "Create User" : "SignUp"}</button>
                        
                    </form>
                </Modal.Body>
            </Modal>
            {/*--------------------------LOGIN---------------------------- */}
            <Modal show={showLogin} onHide={handleCloseLogin}>
                <Modal.Header closeButton>
                    <Modal.Title>Log IN</Modal.Title>
                </Modal.Header >
                <Modal.Body>
                    <form className={style.signUpForm} onSubmit={Login}>
                        <div id={style.errorMessage2}> 
                            
                        </div>
                        <label htmlFor="email">Email*</label>
                        <input type={"email"} placeholder="Enter your email..." required id="email" onChange={event => setEmail(event.target.value)}></input>
                    
                        <label htmlFor="password">Password*</label>
                        <input type={"password"} id="password" required onChange={event => setPassword(event.target.value)}></input>


                        <button type="submit">LogIN</button>

                        <a onClick={handleShowResetPassw} className={style.forgot}><p>Forgot the Password ?</p></a>
                    </form>
                </Modal.Body>
            </Modal>

            {/* CONFIRM UR EMAIL */}
            <Modal show={showConfirmEmail} onHide={handleCloseConfirmEmail}>
                <Modal.Header closeButton>
                    <Modal.Title>Email Confirmation</Modal.Title>
                </Modal.Header >
                <Modal.Body>
                    <div className={style.emailConfirmation}>
                        <div className={style.image}>
                            <img src={emailLogo}></img>
                        </div>
                        <div className={style.text}>
                            Thank you for registering! Before you can start using our service, please confirm your email address by clicking on the verification link that we've sent to your inbox. If you don't see the email, please check your spam folder.
                        </div>
                    </div>
                    
                </Modal.Body>
            </Modal>

            {/*LogOut */}
            <Modal show={showLogout} onHide={handleCloseLogout}>
                <Modal.Header closeButton>
                    <Modal.Title>LogOut</Modal.Title>
                </Modal.Header >
                <Modal.Body>
                    <div>
                        <div className="modal-body">
                            Are you sure you want to log out?
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={style.LogOutButton} onClick={LogOut}>LogOut</button>
                            <button type="button" className={style.CancelLogOutButton} onClick={handleCloseLogout}>Close</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>


              {/*Reset Password */}
              <Modal show={showResetPassw} onHide={handleCloseResetPassw}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header >
                <Modal.Body>
                    <div>
                        <div className="modal-body">

                        <form className={style.signUpForm} onSubmit={resetPassw}>
                            <img src={loading} className={isLoading ? style.active : style.noactive }></img>
                            <div id={style.errorMessage3}> 
                                
                            </div>
                            <label htmlFor="email">Email*</label>
                            <input type={"email"} placeholder="Enter your email..." required id="email" onChange={event => setEmailReset(event.target.value)}></input>
   
                        </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={style.LogOutButton} onClick={resetPassw}>Reset</button>
                            <button type="button" className={style.CancelLogOutButton} onClick={handleCloseResetPassw}>Close</button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>


        </>
    )
}

