import React, { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../../Components/Footer"
import NavBar from "../../Components/NavBar"

import { Icon } from '@iconify/react';

import style from './style.module.css'
import userImg from '../../assets/userprofile.svg'
import { Modal } from "react-bootstrap";
import classNames from "classnames";
import Pagination from "../../Components/Pagination";

import api from "../../service/api";

import password from "../../assets/password.svg"
import username from "../../assets/username.svg"

export default function UserProfile(){

	const [showChangeP, setShowChangeP] = useState(false)
	const [showChangeU, setShowChangeU] = useState(false)
	const [currentP, setCurrentP] = useState("")
	const [passw, setPassw] = useState("")
	const [newUsername, setNewUsername] = useState("")
	const [user, setUser] = useState({})

	const [confirmPassw, setConfirmPassw] = useState("")

	const handleCloseChangeU = () => setShowChangeU(false)
	const handleShowChangeU = () => setShowChangeU(true)
	//Show and hidde changeP modal
	const handleCloseChangeP = () => setShowChangeP(false)
	const handleShowChangeP = () => setShowChangeP(true)

	async function changePassword(event){
		event.preventDefault()
		if(!(confirmPassw === passw)){
        	alert("The passwords dont match")
        }else{
			const response = await api.post("/user/change/password", {
				email: user.email, 
				password: passw, 
				oldPassword: currentP
			})
			.then(message => {
				alert(message.data)
				window.location.reload()
			}).catch(error => {
				if (error.response) {
					alert(error.response.data)
				} else if (error.request) {
					console.log(error.request);
				  } else {
					console.log('Error', error.message);
				  }
			})    
		}
	}

	async function changeUsername(event){
		event.preventDefault()
		
		const response = await api.post("/user/change/username", {
			username: newUsername, 
			email: user.email, 
			password: passw
		})
		.then(message => {
			alert(message.data)
			window.location.reload()
		}).catch(error => {
			if (error.response) {
				alert(error.response.data)
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		})    
		
	}

	//get user information 
	async function getUser(){
		var token = localStorage.getItem("token")

		const response = await api.get("/user", {
			headers: { Authorization: `Bearer ${token}`}
		})
			.then(message => {
				//If the user is succesfuly registered than he can be logged at the website
				setUser(message.data)
			}).catch(error => {
			})    
	}

	useEffect(() => {
		getUser()
	}, [])

    return(
        <>
		<div className={style.body}>
			<NavBar scrolled={true}></NavBar>

			<div className={style.container}>
					<div>
						<img src={userImg} className={style.img}></img>
					</div>
					<div className={style.imgC}>
						<p>Username: <strong className={style.username}>{user.name}</strong></p>
					</div>
					<div className={style.changes}>
						<div className={style.changeP} onClick={handleShowChangeP}>
							<img src={password}></img>
							<p>Change Password</p>
						</div>
						<div className={style.changeD} onClick={handleShowChangeU}>
							<img src={username}></img>
							<p>Change Username</p>	
						</div>
					</div>
			</div>
		   </div>

		    {/* Change Password */}
            <Modal show={showChangeP} onHide={handleCloseChangeP}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header >
                <Modal.Body>
					<form className={style.signUpForm} onSubmit={changePassword}>
                        <div id={style.errorMessage2}> 
                        </div>
                        <label htmlFor="currentP">Current Password*</label>
                        <input type={"password"} required id="currentP" onChange={event => setCurrentP(event.target.value)}></input>
                    
                        <label htmlFor="password">New Password*</label>
                        <input type={"password"} id="password" required onChange={event => setPassw(event.target.value)}></input>

						<label htmlFor="password">Confirm Password*</label>
                        <input type={"password"} id="password" required onChange={event => setConfirmPassw(event.target.value)}></input>

                        <button type="submit">Change Password</button>
                    </form>
                </Modal.Body>
            </Modal>

			 {/* Change Username */}
			 <Modal show={showChangeU} onHide={handleCloseChangeU}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Username</Modal.Title>
                </Modal.Header >
                <Modal.Body>
					<form className={style.signUpForm} onSubmit={changeUsername} >
                        <div id={style.errorMessage2}> 
                        </div>
                        <label htmlFor="username">New Username*</label>
                        <input type={"text"} required id="username" onChange={event => setNewUsername(event.target.value)}></input>
                    
                        <label htmlFor="password">Password*</label>
                        <input type={"password"} id="password" required onChange={event => setPassw(event.target.value)}></input>

                        <button type="submit">Change Username</button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}