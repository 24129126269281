import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import style from './style.module.css'

function getDate(){
    return document.write(new Date().getFullYear)

}

export default function Footer(){
    return(
        <>
            <footer className={style.footer}>
  <div className="container d-flex justify-content-between align-items-center">
    {/* Your existing footer content */}
    <div>
      Copyright &copy; {getDate} All rights reserved | Made with <FontAwesomeIcon icon={faHeart} beatFade style={{color: "#e25555",}} /> by <a href="http://mir.dei.uc.pt/" rel="noopener noreferrer" target="_blank">MIR.dei.uc.pt</a> | Theme by <a href="https://colorlib.com" rel="noopener noreferrer" target="_blank">Colorlib</a>
    </div>

    {/* Add your images here */}
    <div className="d-flex align-items-center">
      <img src="/2022_FCT.png" alt="FCT logo" className="ml-3" style={{ maxWidth: '150px', maxHeight: '30px', marginRight: '15px' }} />
      <img src="/CISUC_2.png" alt="CISUC logo" className="ml-3" style={{ maxWidth: '150px', maxHeight: '30px', marginRight: '15px' }} />
      <img src="/Ci2.png" alt="Ci2 logo" className="ml-3" style={{ maxWidth: '150px', maxHeight: '35px' }} />
    </div>
  </div>
</footer>

        </>
    )
}

