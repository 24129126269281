import React, { useEffect, useState , useMemo} from "react";
import {useNavigate} from "react-router-dom"
import searchOnYoutube from "youtube-search"
import style from "./style.module.css"

import { Icon } from '@iconify/react';
import api from "../../service/api";

import {DebounceInput} from 'react-debounce-input';
import loading from "../../assets/loading.svg"
import classNames from "classnames";


export default function ProcessingSong(props){
    const [state, setState] = useState("")
    const [percent, setPercent] = useState(0)
    const [time, setTime] = useState(Date.now());
    const mysocketid = ""
    const defaultTime = 1000
    var date = new Date()
    const history = useNavigate()

    const song_id = props.song.external_id

    
    useEffect(() => {
        
        const interval = setInterval(() => {
            if(JSON.parse(localStorage.getItem("songs")) != null){
                JSON.parse(localStorage.getItem("songs")).map( song => {
                    if(song_id === song.id){
                        setPercent(song.progress)
                        setState(song.state)
                    }
                })
            }
            console.log(props.song.status)
          }, 100);

        return () => {
            clearInterval(interval);
        };
       
        
    }, [])

    return(
        <>
            {
                ((props.song.status !== "processed") && (percent != 100)) ? (
                    <div className={classNames(style.blog_post,  "d-flex flex-md-row flex-column align-items-start justify-content-start")}>
                        <div className={style.blog_post_image}>
                            <iframe width="485" height="273" src={`https://www.youtube-nocookie.com/embed/${props.song.external_id}`}  title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className={style.blog_post_content}>
                            <div className={style.blog_post_title}>{props.song.title}</div>
                            <div className={style.processing}>
                                <div>
                                    <img src={loading}></img>
                                    <p>{percent}%</p>
                                </div>
                                <p>{state}...</p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={classNames(style.blog_post,  "d-flex flex-md-row flex-column align-items-start justify-content-start")}>
                        <div className={style.blog_post_image}>
                            <iframe width="485" height="273" src={`https://www.youtube-nocookie.com/embed/${props.song.external_id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className={style.blog_post_content}>
                            <div className={style.blog_post_title}><a href={`/music/${props.song.external_id}`}>{props.song.title}</a></div>
                            <div className={style.blog_post_author}>{props.song.artist} on {new Date(props.song.date).getDay()}/{new Date(props.song.date).getMonth()}/{new Date(props.song.date).getFullYear()}
                            <br/>
                                Added to the Queue on {new Date(props.song.createdAt).getDay()}/{new Date(props.song.createdAt).getMonth()}/{new Date(props.song.createdAt).getFullYear()}
                            </div>
                        </div>
                    </div>
                )
            }
           
        </>
    )
}