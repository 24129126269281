import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Parallax } from 'react-parallax'
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";

import style from "./style.module.css"
import classNames from "classnames";

import browseImage from "../../assets/browse.jpg"
import { faSearch, faChevronDown, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import searchOnYoutube from "youtube-search"
import api from "../../service/api";
import { json, useLocation, useNavigate } from "react-router-dom";
import { title } from "process";

import notfound from "../../assets/notfound.svg"
import Pagination from "../../Components/Pagination";
import { Modal } from "react-bootstrap";
import loading from "../../assets/loading.svg"

export default function Browse() {

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    var name = queryParameters.get("name")
    var emotion = queryParameters.get("emotion")

    const [songs, setSongs] = useState([])
    const [search, setSearch] = useState("")
    const [isLoading, setisLoading] = useState(true)

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    //handle logout
    const handleCloseDelete= () => setShowConfirmDelete(false)
    function handleShowDelete(externalid){
		setShowConfirmDelete(true)
		setIdSong(externalid)
	}
    //Variables for Pagination
    //This variables are responsible to mantain the pagination of the songs on the website
    const [idSong, setIdSong] = useState("")

    const [currentPage, setCurrentPage] = useState(1)//By default we are always on page 1
    const [songsPerPage] = useState(3)
    const [isAdmin, setIsAdmin] = useState(false)

    const indexOfLastSong = currentPage * songsPerPage
    const indexOfFirstSong = indexOfLastSong - songsPerPage
    const currentSongs = songs.slice(indexOfFirstSong, indexOfLastSong)

    //Change page
    const paginate = pageNumber => setCurrentPage(pageNumber)
    //This method of pagination was originally create by: https://github.com/bradtraversy/simple_react_pagination/blob/master/src/App.js
    const history = useNavigate()

    
    async function getSongs(){
        const response = await api.get("/song")
        .then(message => {
            setSongs(message.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.request.data)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error);
              }
        })   
        
        setisLoading(false)
    }
    

    async function getSongsbyName(name){
        const response = await api.get(`/song/name/${name}`)
        .then(message => {
            setSongs(message.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.request.data)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error);
              }
        })    

        setisLoading(false)
    }

    async function getSongsByEmotion(emotion){
        const response = await api.get(`/song/emotion/${emotion}`)
        .then(message => {
            setSongs(message.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.request.data)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error);
              }
        })   
        
        setisLoading(false)
    }

    async function getSongsbyAll(emotion, name){
        const response = await api.get(`/song/name/${name}/emotion/${emotion}`)
        .then(message => {
            setSongs(message.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.request.data)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error);
              }
        }) 

        setisLoading(false)
    }

    async function isUserAdmin(){
        var token = localStorage.getItem("token")

        const response = await api.get("/user", {
            headers: { Authorization: `Bearer ${token}`}
        })
            .then(message => {
                //If the user is succesfuly registered than he can be logged at the website
                if(message.data.admin){
                    setIsAdmin(true)
                }
            }).catch(error => {
                if (error.response) {
                } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            })    

    }

    async function deleteSong(id){
        const response = await api.delete(`/song/delete/${id}`)
            .then(message => {
                window.location.reload()
            }).catch(error => {
                if (error.response) {
                } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            })    
    }

    function updateUrlParams(key, value){
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set(key, value)
        const newUrl = window.location.pathname + "?" + searchParams.toString()
        window.location = newUrl
    }

    function deleteUrlParams(key){
        const url = new URL(window.location.href)
        url.searchParams.delete(key)
        window.location = url.toString()
    }
    
    //SearchBar submit
    function handleSubmit(event){
        //if the name is null we can just reload the page
        if(search.length === 0){
            deleteUrlParams("name")
        }else{
            event.preventDefault();
            updateUrlParams("name", search)
        }

    }

    //
    function handleCheckBoxChange(id){
        onlyOneCheckBox(id)
    }
    //Only one checkbox
    function onlyOneCheckBox(id){

        //Get the value of the id 
        var input = document.getElementById(id).checked
        //Reset all the checkboxes 
        document.getElementById("Happy").checked = false
        document.getElementById("Calm").checked = false
        document.getElementById("Sad").checked = false
        document.getElementById("Tense").checked = false

        //Check or uncheck The one who has passed the id
        if(input){
            document.getElementById(id).checked = true
            //After The user has checked the checkbox we have to search the songs by emotion
            //But if we already have a name on the params we have to search by both
            updateUrlParams("emotion", id) 
            
        }else{
            document.getElementById(id).checked = false
            //This means that the user no longer wants to see the songs by emotion
            //so we have to clear the emotion param
            deleteUrlParams("emotion")
        }
        
    }

    function amIChecked(id){
        //See if te params have their ID
        if(id === emotion){
            return true
        }else{
            return false
        }
    }


    //On Load Page
    useEffect(() => {
        //If we dont have any param we will just look for all the songs
        if( (name == null) && (emotion == null)){
            getSongs()
        }
        //If the param name is on the URL we have to search the musics only by name
        else if( (name != null) && (emotion == null)){
            getSongsbyName(name)
        }
        //If the the param emotion is the only one available
        else if( (name == null) && (emotion != null)){
            getSongsByEmotion(emotion)
        }
        //If the both are available we will search by all the params
        else if( (name != null) && (emotion != null)){
            getSongsbyAll(emotion, name)
            
        }
        isUserAdmin()
    }, [])

    if (isLoading){
        return(
            <div>
                <NavBar scrolled={true}></NavBar>
                <div className={style.Loading}>
                    <img src={loading}></img>
                </div>
            </div>
        )
    }

    return (
        <>
            {/*Loading Bar*/}
            <div className="super_container">
                <NavBar active = {3} ></NavBar>
                <Parallax className="parallax_background parallax-window" speed={0.8} bgImage={browseImage}>
                    <div className={style.home}>
                        <div className={classNames(style.home_container, "d-flex flex-column align-items-center justify-content-center")}>
                            <div className={style.home_content}>
                                <div className={style.home_title}>
                                    <h1>browse</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </Parallax>

                <div className={style.blog}>
                    <div className="container">
                        <div className="row">
                            <div className={classNames("col-lg-3 order-lg-1 order-2", style.sidebar_col)}>
                                <div className={style.sidebar}>
                                    <div className={style.sidebar_search}>
                                        <div className={style.sidebar_title}>Search</div>
                                            {/*SearchBar */}
                                            <form className={style.search_form} id="search_form" action="/browse/" onSubmit={handleSubmit}>
                                                <input type="text" className={style.search_input} placeholder="Search" 
                                                    onChange={event => setSearch(event.target.value)}/>
                                                <button className={style.search_button} type="submit"><FontAwesomeIcon icon={faSearch}/></button>
                                            </form>
                                    </div>

                                    <div className={style.sidebar_tags}>
                                        <div className={style.sidebar_title}>Feeling</div>
                                        <div className={style.tags}>
                                            <form>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="Happy" onChange={() => onlyOneCheckBox("Happy")} checked={amIChecked("Happy")}></input>
                                                    <label class="form-check-label" for="Happy">
                                                        Happy
                                                    </label>
                                                </div>

                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" value="" id="Calm" onChange={() => onlyOneCheckBox("Calm")} checked={amIChecked("Calm")}></input>
                                                        <label class="form-check-label" for="Calm">
                                                            Calm
                                                        </label>
                                                    </div>

                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="Sad" onChange={() => onlyOneCheckBox("Sad")} checked={amIChecked("Sad")}></input>
                                                        <label class="form-check-label" for="Sad">
                                                            Sad
                                                        </label>
                                                    </div>

                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" value="" id="Tense" onChange={() => onlyOneCheckBox("Tense")} checked={amIChecked("Tense")}></input>
                                                        <label class="form-check-label" for="Tense">
                                                            Tense
                                                        </label>
                                                    </div>
                                                </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={classNames("col-lg-9", style.blog_col, "order-lg-2 order-1")}>

                                <div id = {style.SearchResults} className={name ? style.active : style.noactive}>
                                    <p>Results for: "{name}"</p> 
                                </div>

                                <div className={style.blog_posts}>
                                {

                                    songs.length == 0 ? (
                                            <div className={style.notFound}>
                                                <div>
                                                    <img src={notfound}></img>
                                                </div>
                                                <div>
                                                    <h1>Results Not Found</h1>
                                                </div>
                                                <div>
                                                    We regret that your search returned no results. We advise you to change your search terms or come back later. We appreciate you using our website.
                                                </div>
                                            </div>
                                    ) : (
                                        currentSongs.map(song => {


                                            const date = new Date(song.date)
                                            const formattedDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                                            const hrefURL = `?emotion=${song.general_classification}`
                                            const hrefURL_music = `/music/${song.external_id}` 
                                                return(
                                                    <div className={classNames(style.blog_post, "d-flex flex-md-row flex-column align-items-start justify-content-start")}>
                                                        <div className={style.blog_post_image}>
                                                            <img src={song.thumbnailHQ} alt={song.title}/>
                                                            <div className={style.blog_post_date}><a href={hrefURL}>{song.general_classification}</a></div>
                                                        </div>
                                                        <div className={style.blog_post_content}>
                                                            <div className={style.blog_post_title}><a href={hrefURL_music}>{song.title}</a></div>
                                                            <div className={style.blog_post_author}>{song.artist} on {formattedDate}</div>
                                                            <div className={style.blog_post_link}><a href={hrefURL_music}>See More</a></div>
                                                            <div>
                                                                {
                                                                    isAdmin ? (
                                                                        <>  
                                                                            <button className={style.delete_button} onClick={() => handleShowDelete(song.external_id)}>Delete Song</button>
                                                                        </>
                                                                        
                                                                    ) : (
                                                                        <></>
                                                                    ) 
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                        })
                                    )
                                }

                                </div>
                            </div>
                        </div>
                        
                        {
                            songs.length == 0 ? (
                                <div></div>
                            ) : (
                                <div>
                                    <Pagination
                                        songsPerPage={songsPerPage}
                                        totalSongs={songs.length}
                                        paginate={paginate}
                                        currentPage = {currentPage}
                                    />
                                </div>
                            )
                        }
                        
                    </div>
                </div>
                <Footer></Footer>
            </div>


            <Modal show={showConfirmDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header >
                <Modal.Body>
                    <div>
                        <div className="modal-body">
                            Are you sure you want to delete this song ? 
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={style.LogOutButton} onClick={() => deleteSong(idSong)}>Delete</button>
                            <button type="button" className={style.CancelLogOutButton} onClick={handleCloseDelete}>Close</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}