import React, { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../../Components/Footer"
import NavBar from "../../Components/NavBar"

import { Icon } from '@iconify/react';

import style from './style.module.css'

import { Modal } from "react-bootstrap";
import classNames from "classnames";
import Pagination from "../../Components/Pagination";

import api from "../../service/api";

export default function ManageUser(){
	const history = useNavigate()
	const [isAdmin, setIsAdmin] = useState(false)
	//Make this accessible only for admin users
	//get user information 
	async function getUser(){
	var token = localStorage.getItem("token")
	
	if(!token){
		history("/*")
	}
	const response = await api.get("/user", {
		headers: { Authorization: `Bearer ${token}`}
	})
		.then(message => {
			console.log(message.data)
			if(!(message.data.admin)){
				history("/*")
			}
		}).catch(error => {
			if (error.response) {
				console.log(error.response)
			  } else if (error.request) {
				console.log(error.request);
			  } else {
				console.log('Error', error.message);
			  }
		})    

	
}
	const [users, setUsers] = useState([])
	const [search, setSearch] = useState("")
	const [email, setEmail] = useState("")
	const [name, setName] = useState("")

	 //Variables for Pagination
    //This variables are responsible to mantain the pagination of the songs on the website
    const [currentPage, setCurrentPage] = useState(1)//By default we are always on page 1
    const [usersPerPage] = useState(15)

    const indexOfLastUser= currentPage * usersPerPage
    const indexOfFirstUser = indexOfLastUser - usersPerPage
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser)

    //Change page
    const paginate = pageNumber => setCurrentPage(pageNumber)

	const [showBlock, setshowBlock] = useState(false)
	function handleShowBlock(email, name){
		setshowBlock(true)
		setEmail(email)
		setName(name)
	}
    const handleCloseBlock = () => setshowBlock(false)
	async function getAllUsers(){
        const response = await api.get(`/user/getall/`)
            .then(message => {
				setUsers(message.data)
            }).catch(error => {
                if (error.response) {
                } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            })    
    }

	async function handleSubmit(event){
		event.preventDefault()

		const user = {
			name: search, 
			email: search, 
			blocked: document.getElementById("checkbox").checked
		}

		const response = await api.post("/user/bynameoremail", user)
		.then(message => {
			setUsers(message.data)
		}).catch(error => {
			if (error.response) {
			} else if (error.request) {
				console.log(error.request);
			  } else {
				console.log('Error', error.message);
			  }
		})    
	}

	async function checkbox(){
		if(document.getElementById("checkbox").checked){
			const response = await api.get("/user/blocked")
			.then(message => {
				
				setUsers(message.data)
			}).catch(error => {
				if (error.response) {
				} else if (error.request) {
					console.log(error.request);
				  } else {
					console.log('Error', error.message);
				  }
			})    
		}else{
			if(search){

				const user = {
					name: search, 
					email: search, 
					blocked: document.getElementById("checkbox").checked
				}

				const response = await api.post("/user/bynameoremail", user)
					.then(message => {
						setUsers(message.data)
					}).catch(error => {
						if (error.response) {
						} else if (error.request) {
							console.log(error.request);
						} else {
							console.log('Error', error.message);
						}
					})    
			}else{
				getAllUsers()
			}
		}
	}

	async function blockUser(email){
		const response = await api.post(`/user/blockuser/${email}`)
			.then(message => {
				window.location.reload()
			}).catch(error => {
				if (error.response) {
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			})  
	}

	async function unblockUser(email){
		const response = await api.post(`/user/unblockuser/${email}`)
		.then(message => {
			alert("User Unblocked")
			window.location.reload()
		}).catch(error => {
			if (error.response) {
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}) 	
	}
	useEffect(() => {
		getUser()
		getAllUsers()
	}, [])
    return(
        <>
          <NavBar></NavBar>
		   <div className="super_container">
			<div className={style.home}>
                <div className={classNames(style.home_container, "d-flex flex-column align-items-center justify-content-center")}>
                    <div className={style.home_content}>
                        <div className={style.home_title}>
                            <h1>Manage Users</h1>
                        </div>
						
						<div id={style.SearchBar} className = {search ? style.active : style.noactive}>
							<div className={style.filter}>
								<form className={style.form} onSubmit={handleSubmit}>
									<input 
										debounceTimeout={300}
										type={"text"} 
										placeholder="Insert the username or email of the user..." 
										onChange={event => setSearch(event.target.value)}
										required
									></input>
									<button type="submit"><Icon icon="fe:search" height={"36px"}/></button> 
								</form>
							</div>
							<div className={style.radio}>
								<label htmlFor="radio">Only blocked Users</label>
								<input type={"checkbox"} id={"checkbox"} onChange={checkbox}></input>
							</div>
								
							</div>
					

                    </div>
                </div>
			</div>

			<div className={style.table}>
				<table class="table">
					<thead>
						<tr>
							<th scope="col">Name</th>
							<th scope="col">Email</th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						{
							currentUsers.map((user) => (
								<>
									<tr>
									<td>{user.name}</td>
									<td>{user.email}</td>
									{
										user.blocked_at == null ? (
											<>
												<td>
													{
														user.email === "admin@admin.com" ? (
															<></>
														) : (
															<button className={style.block_button} onClick={() => handleShowBlock(user.email, user.name)}
															>Block</button>
														)
													}
													
												</td>
											</>
										): (
											<>	
												<div className={style.blockeduser}>
													<button className={style.unblock_button} onClick={() => unblockUser(user.email)}>Unblock User</button>
													<p>Blocked at: {new Date(user.blocked_at).getDay()}/{new Date(user.blocked_at).getMonth()}/{new Date(user.blocked_at).getFullYear()}</p>
												</div>
											</>
										)
									}
									
									</tr>

									<Modal show={showBlock} onHide={handleCloseBlock}>
										<Modal.Header closeButton>
											<Modal.Title>Block User</Modal.Title>
										</Modal.Header >
										<Modal.Body>
											<div>
												<div className="modal-body">
													Are you sure you want to block {name} ? 
												</div>
												<div className="modal-footer">
													<button type="button" className={style.LogOutButton} onClick={() => blockUser(email)}>Block</button>
													<button type="button" className={style.CancelLogOutButton} onClick={handleCloseBlock}>Close</button>
												</div>
											</div>
										</Modal.Body>
									</Modal>
								</>
								
							))
						}
								
					</tbody>
				</table>

				{
                            users.length == 0 ? (
                                <div></div>
                            ) : (
                                <div>
                                    <Pagination
                                        songsPerPage={usersPerPage}
                                        totalSongs={users.length}
                                        paginate={paginate}
                                        currentPage = {currentPage}
                                    />
                                </div>
                            )
                        }
			</div>

				<Footer></Footer>
			</div>

			
        </>
    )
}