import React, { createContext, useContext, useEffect} from 'react';
import { io } from 'socket.io-client';
import { socketIoUrl } from '../config';

const SocketContext = createContext();
// Custom hook to access the socket context
export const useSong = () => useContext(SocketContext);

// SocketProvider component
export const SocketProvider = ({ children }) => {
    const socket =  io(socketIoUrl , { 
        transports: ['websocket', 'polling', 'flashsocket'] 
      });
  
  function updateQueueStorage(songs){
    localStorage.setItem("songs", JSON.stringify(songs))
  }

  useEffect(() => {
    //Listen to the logs the server is sending about the songs that are classifying
    socket.on('progress', (data) => {
      // Receive the data about the songs
      const songs = [] 

      //If the song is already in the queue we only have to update the object inthe array that contains the same id as the song
      if(JSON.parse(localStorage.getItem("songs")) != null){
        //se recevber uma musica com o id igual a song e um array vazio a unica coisa que tenho de fazer é para nao adicionare algo com o id igual 
        //é ao array 
        //map the songs to see wich song we have to update 
        //esta flag é para saber se foi encontyrado alguma song igual
        var flag = false
        JSON.parse(localStorage.getItem("songs")).map( song => {
            //now we have to see if the id matches 
            if(song.id === data.song_id){
                const updateData = {
                    id: data.song_id, 
                    progress: data.progress, 
                    state: data.state
                }
                flag = true
                songs.push(updateData)
            }else{
                songs.push(song)
            }
            
        })
        if(!flag){
            songs.push({ id: data.song_id, progress: data.progress,  state: data.state})
        }
        updateQueueStorage(songs)
      }else{
        //If the queue is empty we just have to add to the array 
        const song = [{ id: data.song_id, progress: data.progress,  state: data.state}]
        updateQueueStorage(song)
      }
    });

    
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};