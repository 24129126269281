import React, { useEffect, useState, useMemo } from "react";
import {useNavigate} from "react-router-dom"
import searchOnYoutube from "youtube-search"
import style from "./style.module.css"

import { Icon } from '@iconify/react';
import api from "../../service/api";

import {DebounceInput} from 'react-debounce-input';

import io from 'socket.io-client'
import { socketIoUrl, ytApiKey } from '../../config';

export default function SearchBar(){
    const [name, setName] = useState('')
    const [video, setVideo] = useState([])
    const [userId, setUserId] = useState(null)
    const history = useNavigate()

    async function isTheSongAlreadyClassified(id){
        //see if the link of the song already exists in the database
        var state = 0
        const response = await api.get(`/song/${id}`)
        .then(response => {
            if(!(response.data == null)){
                state = 1 // 1 : Means that is already classified on the database 
            }else{
                state = 0 // 0 : Means that is not classified on the database
            }
            return 10
        }).catch(error => {
            if (error.response) {
                console.log(error.response);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
        })  
        return state
    }

    	//get user information 
	async function getUserID(){
		var token = localStorage.getItem("token")
	
		const response = await api.get("/user", {
			headers: { Authorization: `Bearer ${token}`}
		})
			.then(message => {
				setUserId(message.data.email)
			}).catch(error => {
                setUserId(null)
		})    
	}
    async function classifySong(id){
        const song_id = id
        //Add the song to the socket
        const socket =  io(socketIoUrl,{ 
            transports: ['websocket', 'polling', 'flashsocket'] ,
            query : {song_id}, 
        });

        socket.on("connect", async () => {
        //Send the id to the bakend so the song can be classified
        const response = await api.post(`/song/classification/song/${id}/user/${userId}`)
        .then(response => {
            alert(response.data)
            history(`/queue`)
        }).catch(error => {
            if (error.response) {
                alert(error.response.data);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
        })  
        })
       
    }

    useEffect( () => {
        async function search(name){
            //As soon as the user starts writing at the search we are going to look for the results at youtube
            var opts = {
                maxResults: 4,
                key: ytApiKey
              };
            
              searchOnYoutube(name, opts, async function(err, results) {
                if(err) return console.log(err);
                
                //If the function finds the videos we have to ask to the database if the song already exists in the database

                //Create an array of objects(type: Song) with the results that are coming from youtube
                const videos = await Promise.all( results.map(async (video) => ({
                    id: video.id, 
                    thumbnail : video.thumbnails.default.url, 
                    title: video.title, 
                    isTheSongAlreadyClassified: await isTheSongAlreadyClassified(video.id)
                })))

               
                setVideo(videos)
              });
        }
          

        if (name && name.trim() !== '') {
            search(name);
        }
    }, [name, setName])

    useEffect(() => {
        getUserID()
    }, [])
    function handleSubmit(){
        history(`/browse/?name=${name}`)
    }

    return(
        <>
            <div id={style.SearchBar} className = {name ? style.active : style.noactive}>
                <div className={style.filter}>
                    <form className={style.form} onSubmit={handleSubmit}>
                        <DebounceInput 
                            minLength={3}
                            debounceTimeout={500}
                            type={"text"} 
                            placeholder="Search for a song or insert a YouTube link..." 
                            onChange={event => setName(event.target.value)}
                            required
                        ></DebounceInput>
                        <button type="submit"><Icon icon="fe:search" height={"36px"}/></button> 
                    </form>
                </div>
            </div>

            <div id={style.searchresults}  className = {name ? style.active : style.noactive}>
                {
                    name ? ( video.map(video => {
                        if(video.isTheSongAlreadyClassified === 1){
                            return(
                                <div onClick={() => history(`/music/${video.id}`)}>
                                    <div className={style.imageResult}>
                                        <img src={video.thumbnail} alt={`${video.title} video thumbnail`}></img>
                                    </div>
                                    <div>
                                        <div className={style.descriptionResult}>
                                            <p>{video.title}</p>
                                        </div>
                                        <div className={style.isAlreadyClassified}>
                                            <p>This music is already classified</p>
                                            <a href={`/music/${video.id}`}>See More</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        }else{
                            return(
                                <div>
                                    <div className={style.imageResult}>
                                        <img src={video.thumbnail} alt={`${video.title} video thumbnail`}></img>
                                    </div>
                                    <div>
                                        <div className={style.descriptionResult}>
                                            <p>{video.title}</p>
                                        </div>
                                        <div className={style.classifyButton}>
                                            <button onClick={() => classifySong(video.id)}>Classify</button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        
                    })) : ""
                    }
            </div>
        </>
    )
}