import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../../Components/NavBar"
import { Audio } from 'react-loader-spinner'

import api from "../../service/api";

import loading from "../../assets/loading.svg"
import loadingWhite from "../../assets/loading_white.svg"
import expiredEmail from "../../assets/expiredemail.svg"
import warning from "../../assets/warning.svg"
import verified from "../../assets/verified.svg"

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import style from "./style.module.css"

export default function VerifiedUser(){
    const {token} = useParams()
    const [userv, setUserV] = useState(false)
    const [isLoading, setisLoading] = useState(true)

    const [isSendigEmail, setisSendingEmail] = useState(false)
    const [emailSent, setEmailSent] = useState(false)

    const history = useNavigate()
    const [errorMessage, setErrorMessage] = useState(null)

    async function verifyUser(){
        //First we have to see if the token is valid 
        //That means if the token is for a existent user on the database or is expired
        const userData = await api.get("/user", {
            headers: { Authorization: `Bearer ${token}`}
        })
            .then(async message => {
                await confirmToken()
            }).catch(error => {
                if (error.response) {
                    setErrorMessage(error.response.data.error)
                    console.log(error.response.data.error)
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            })    

        setisLoading(false)
    
    }

    async function confirmToken(){
        const response = await api.get(`/confirm/${token}`)
        .then(message => {
            setUserV(true)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
        })    
    }

    function redirectTo404(){
        history("/error/notfound")
    }

    async function sendNewEmail(){
        setisSendingEmail(true)
        const response = await api.get("/user/newtoken", {
            headers: { Authorization: `Bearer ${token}`}
        })
            .then(message => {

            }).catch(error => {
                if (error.response) {
                    console.log(error.request.data)
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
        })  
        
        setisSendingEmail(false)
        setEmailSent(true)
    }

    useEffect(() => {
        verifyUser()
    }, [])

    if (isLoading){
        return(
            <div>
                <NavBar scrolled={true}></NavBar>
                <div className={style.Loading}>
                    <img src={loading}></img>
                </div>
            </div>
        )
    }
    return(
        <>
        <div className={style.body}>
            <NavBar scrolled = {true}></NavBar>
            {

                errorMessage === "Invalid token!" ? (
                    <div className={style.InvalidToken}>
                        <div className={style.InvalidToken_img}>
                            <img src={warning}></img>
                        </div>
                        <div className={style.InvalidToken_txt}>
                            We're sorry, but your token is invalid. Please check your email and click the confirmation link to verify your account. If you still experience issues, please contact our support team for assistance.
                        </div>
                    </div>
                ) : (
                    //Here is the Page that we will show if the user dont exist in the database so he needs to register
                    //This page will be the 404 not found page
                    errorMessage === "The user does not exist!" ? (
                        redirectTo404()
                    ) : (
                        //Now if the token has expired we have to tell the user to ask for another email so he can confirm again the email
                        errorMessage == "Token expired!" ? (
                            <div className={style.expiredEmail}>
                                <div className={style.expiredEmail_img}>
                                    <img src={expiredEmail}></img>
                                </div>
                                <div className={style.expiredEmail_txt}>
                                    Sorry, the email confirmation link has expired. Please request a new email confirmation link to complete the registration process. 
                                </div>
                                <div className={style.requestNewEmail}>
                                    <div onClick={sendNewEmail}>
                                        <img src={loadingWhite} className={isSendigEmail ? style.active : style.noactive}></img>
                                        <FontAwesomeIcon className={emailSent ? style.emailSent : style.emailNotSent} icon={faCheck}></FontAwesomeIcon>
                                        Request New Email
                                    </div>
                                </div>
                            </div>
                        ) : (
                            //If we dont  have any error the the user will be confirmed 
                            userv ? (
                                <div className={style.VerifiedUser}>
                                    <div className={style.image}>
                                    <img src={verified}></img>
                                    </div>
                                    <div className={style.text}>
                                        Congratulations! Your account has been created successfully. You can now log in and start using our service. Thank you for joining us!
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )
                        )
                    )
                )
        }        
        </div>   
    </>
    )
}