import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import api from "../../service/api";
import Footer from "../../Components/Footer"
import NavBar from "../../Components/NavBar"

import { faThumbsUp, faEye, faComments, faSmileBeam, faCalendarDay, faThumbsDown , faFaceFrown, faFaceGrimace, faFaceSmile } from '@fortawesome/free-solid-svg-icons'


import musicImage from '../../assets/music.jpg'
import waveImage from "../../assets/dQw4w9WgXcQ.png"

import style from './style.module.css'

import { Parallax } from "react-parallax"

import classNames from "classnames";

import notAvailable from "../../assets/notAvailable.svg"

import loading from "../../assets/loading.svg"

import {formatDate} from '../../Utils.js'



export default function Music(){
	const {external_id} = useParams()
	const [song, setSong] = useState()

	const [needsAuth, setNeedsAuth] = useState(false)
	const [isLoading, setisLoading] = useState(true)

	const [agrees, setAgrees] = useState(0)
	const [disagarees, setDisagrees] = useState(0)
	const [date, setDate] = useState(new Date())

	const [userId, setUserId] = useState(0)
	//Variable to stay in track wether a user jas liked the song classification or not
	const [hasLiked, setHasLiked] = useState(0)
	const [hits, setHits] = useState(0)
	const [emotionIcon, setEmotionIcon] = useState()

	async function getSong(){
		const response = await api.get(`/song/${external_id}`)
        .then(message => {
            setSong(message.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.request.data)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error);
              }
        })  
		
		setisLoading(false)
	}

	async function getAgrees(){
		const response = await api.get(`/feedback/agrees/${song.id}`)
        .then(response => {
            setAgrees(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.request.data)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error);
              }
        })    
	}

	async function getDisagrees(){
		const response = await api.get(`/feedback/disagrees/${song.id}`)
        .then(response => {
            setDisagrees(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.request.data)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error);
              }
        })    
	}

	async function agreeOrDisagree(id){
		if(!localStorage.getItem("token")){
			setNeedsAuth(!needsAuth)
		}else{
			if(id == 1){
				//This means that the user agrees with the classification
				const response = await api.post(`/feedback/agree/disagree/${id}/user/${userId}/song/${song.id}`)
				.then(message => {
					getAgrees()
					getDisagrees()
				}).catch(error => {
					if (error.response) {
						console.log(error.response.data)
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log('Error', error);
					}
				})  
			}else{
				//This means that the user disagarees 
				const response = await api.post(`/feedback/agree/disagree/${id}/user/${userId}/song/${song.id}`)
				.then(message => {
					getDisagrees()
					getAgrees()
				}).catch(error => {
					if (error.response) {
						console.log(error.response.data)
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log('Error', error);
					}
				})  
			}
		}
	}

	//get user information 
    async function getUserID(){
        var token = localStorage.getItem("token")

        const response = await api.get("/user", {
            headers: { Authorization: `Bearer ${token}`}
        })
            .then(message => {
                //If the user is succesfuly registered than he can be logged at the website
                setUserId(message.data.id)
            }).catch(error => {
                if (error.response) {	
					console.log(error.response)
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            })    
    }

	async function getHasLiked(){
		var token = localStorage.getItem("token")

        const response = await api.get(`/feedback/opinion/${userId}/${song.id}`)
            .then(response => {
				console.log(response.data)
                if(response.data[0].agree == 1){
					setHasLiked(1)
				}else{
					setHasLiked(2)
				}
            }).catch(error => {
                if (error.response) {	
					console.log(error.response)
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            })    
	}

	//Update hits(visits on the song) onLoad 
	async function updateHits(){
        const response = await api.post(`/song/hits/${song.id}`)
            .then(response => {
            }).catch(error => {
                if (error.response) {	
					console.log(error.response)
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            })    
	}

	//
	async function getHits(){
        const response = await api.get(`/song/hits/${song.id}`)
            .then(response => {
				setHits(response.data)
            }).catch(error => {
                if (error.response) {	
					console.log(error.response)
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            })    
	}

	async function undoFeedback(){
		const response = await api.delete(`/feedback/${userId}/${song.id}`)
		.then(response => {
			setHasLiked(0)
			getAgrees()
			getDisagrees()
		}).catch(error => {
			if (error.response) {	
				console.log(error.response)
			  } else if (error.request) {
				console.log(error.request);
			  } else {
				console.log('Error', error.message);
			  }
		})    	
	}

	//Onload get the song data
	useEffect(() => {
		getSong()
	}, [])

	//Get the agrees and disagrees
	useEffect(() => {
		if(song){
			setDate(new Date(song.date)) // set the date of the song
			getUserID()
			getAgrees()
			getDisagrees()
			updateHits()
			getHits()
			if(song.general_classification == "Calm"){
				setEmotionIcon(faFaceSmile)
			}else if(song.general_classification == "Tense"){
				setEmotionIcon(faFaceGrimace)
			}else if(song.general_classification == "Sad"){
				setEmotionIcon(faFaceFrown)
			}else if(song.general_classification == "Happy"){
				setEmotionIcon(faSmileBeam)
			}


			
			
		}
	}, [song])

	useEffect(() => {
		if(song){
			getHasLiked()
		}
	}, [agrees])

	useEffect(() => {
		if(song){
			getHasLiked()
		}
	}, [disagarees])
	
    if (isLoading){
        return(
            <div>
                <NavBar scrolled={true}></NavBar>
                <div className={style.Loading}>
                    <img src={loading}></img>
                </div>
            </div>
        )
    }

	
	
    return(
        <>
			{
		
				song ? (
						<div className="super_container">
						<NavBar isNotAuthenticated={needsAuth}></NavBar>
						
						<Parallax className="parallax_background parallax-window" speed={0.8} bgImage={musicImage}>
							<div className={style.home}>
								<div className={classNames(style.home_container, "d-flex flex-column align-items-center justify-content-center")}>
									<div className={classNames(style.home_content, "text-center")}>
										<div className={style.home_title}>
											<h1>{song.title}<br/>{song.general_classification} </h1>
											<div className={classNames(style.home_subtitle, "text-center")}>{`${formatDate(date)}`}</div>
											
										</div>
									</div>
								</div>


								<div className={style.home_player_container}>
									<div className="container">
										<div className="row">
											<div className="col-lg-9 offset-lg-3">
												<div className={classNames(style.player, "d-flex flex-row align-items-start justify-content-start s1")}>
													<div className={style.player_content}>
														<img src={waveImage} alt="Waveform"/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							
						</Parallax>

						
						<div className={style.episode_container}>
							<div className={style.episode_image_container}>
								<div className="container">
									<div className="row">
										<div className="col-lg-3">
											<div className={style.episode_image}><img src={song.thumbnailHQ} alt={song.title}/></div>
										</div>
									</div>
								</div>
							</div>


							<div className="container">
								<div className="row">
									<div className={classNames("col-lg-3 order-lg-1 order-2", style.sidebar_col)}>
										<div className={style.sidebar}>
											<div className={style.sidebar_list}>
											<div className={style.show_fav_count}><FontAwesomeIcon icon={emotionIcon} bounce></FontAwesomeIcon> {song.general_classification} </div>
												<div className={style.show_fav_count}><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> {hits} Views</div>
												<div className={style.show_fav_count}><FontAwesomeIcon icon={faCalendarDay}></FontAwesomeIcon> {`${formatDate(date)}`}</div>
												
											</div>
										</div>
									</div>

									<div className={classNames("col-lg-9", style.episode_col ,"order-lg-2 order-1")}>
										<div className={style.AgreeOrDisagree}>
											Do you agree with this classification ? 
											<div>
												<div className={style.agree}>
													<div className={style.page_nav}>
														<ul className="d-flex flex-row">
															<p>{agrees}</p>
															<li className={(hasLiked == 1) ? style.active : ""}>
																{
																	hasLiked == 1 ? (
																		<button onClick={undoFeedback}>
																			<FontAwesomeIcon icon={faThumbsUp} ></FontAwesomeIcon>
																		</button>
																	) : (
																		<button onClick={() => agreeOrDisagree(1)}>
																			<FontAwesomeIcon icon={faThumbsUp} ></FontAwesomeIcon>
																		</button>
																	)
																}
																
															</li>
															<li className={(hasLiked == 2) ? style.active : ""}>
																{
																	hasLiked == 2 ? (
																		<button className={style.thumbsDown} onClick={undoFeedback}>
																			<FontAwesomeIcon icon={faThumbsDown} ></FontAwesomeIcon>
																		</button>
																	) : (
																		<button className={style.thumbsDown} onClick={() => agreeOrDisagree(2)}>
																			<FontAwesomeIcon icon={faThumbsDown} ></FontAwesomeIcon>
																		</button>
																	)
																}
																
															</li>
															<p>{disagarees}</p>
														</ul>
													</div>
												</div>
												<div style={style.disagree}></div>
										</div>
									</div>
										<div className={style.intro}>
											<div className={style.section_title}>{song.title}</div>
											<div className={style.intro_text}>
												<p>{song.description}
												</p>
											</div>
										</div>

										<div className={style.guests}>
											<iframe width="560" height="315" src={`https://www.youtube.com/embed/${song.external_id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
										</div>

										<div className={style.guests}>
											<div className={style.section_title}>Classification Details</div>
											<div className="accordion" id="accordion">
												<div className="card">
												<div className="card-header" id="headingOne">
													<h2 className="mb-0">
													<button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
														Lyrics Classification
													</button>
													</h2>
												</div>
											
												<div id="collapseOne" className={classNames("collapse", style.show)} aria-labelledby="headingOne" data-parent="#accordion">
													<div className="card-body">
													Some placeholder content for the first accordion panel. This panel is shown by default, thanks to the <code>.show</code> class.
													</div>
												</div>
												</div>
												<div className="card">
												<div className="card-header" id="headingTwo">
													<h2 className="mb-0">
													<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
														Vocals Classification
													</button>
													</h2>
												</div>
												<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
													<div className="card-body">
													Some placeholder content for the second accordion panel. This panel is hidden by default.
													</div>
												</div>
												</div>
												<div className="card">
												<div className="card-header" id="headingThree">
													<h2 className="mb-0">
													<button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
														Instrumental Classification
													</button>
													</h2>
												</div>
												<div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
													<div className="card-body">
													And lastly, the placeholder content for the third and final accordion panel. This panel is hidden by default.
													</div>
												</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


						<Footer></Footer>
						{/**/}
						</div>

				) : (
					<div className="super_container">
                    <NavBar active = {3} scrolled={true}></NavBar>

                    <div className={style.songNotAvailable}>
						<div className={style.songNotAvailable_Image}>
							<img src={notAvailable}></img>
						</div>
						<div className={style.songNotAvailable_Text}>
							This song is not available
						</div>
					</div>
                </div>
				)
			}
           
        </>
    )
}