import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Home from './Pages/Home'
import Queue from './Pages/Queue';
import Browse from './Pages/Browse';
import About from './Pages/About';
import Music from './Pages/Music';
import VerifiedUser from './Pages/VerifiedUser';
import NotFound404 from './Pages/NotFound404';
import ManageUser from './Pages/ManageUser';
import UserProfile from './Pages/UserProfile';
import ResetPassw from './Pages/ResetPassw';
export default function Router(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home/>}></Route>
                <Route path='/queue' element={<Queue/>}></Route>
                <Route path='/browse' element={<Browse/>}></Route>
                <Route path='/about' element={<About/>}></Route>
                <Route path='/music/:external_id' element={<Music/>}></Route>
                <Route path='/manage/users' element={<ManageUser></ManageUser>}></Route>
                <Route path='/confirm/:token' element={<VerifiedUser></VerifiedUser>}></Route>
                <Route path='/error/notfound' element={<NotFound404></NotFound404>}></Route>
                <Route path='/userprofile' element={<UserProfile></UserProfile>}></Route>
                <Route path = '*' element={<NotFound404></NotFound404>}></Route>
                <Route path='/resetpassword/:token' element={<ResetPassw></ResetPassw>}></Route>
            </Routes>
        </BrowserRouter>
    )
}