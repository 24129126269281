import React, { useEffect } from 'react';

import style from "./style.module.css"

import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import classNames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Pagination (props) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(props.totalSongs / props.songsPerPage); i++) {
    pageNumbers.push(i);
    
    }

    
  return (
    <div className={classNames("row", style.page_nav_row)}>
        <div className="col">
            <div className={classNames(style.page_nav, "d-flex flex-row align-items-center justify-content-center")}>
                <ul className="d-flex flex-row">
                    {
                        pageNumbers.map(number => (
                            <li className={(props.currentPage === number) ? style.active : ""}><a onClick={() => props.paginate(number)} href="#">{number}</a></li>
                        ))
                    }

                    {
                        pageNumbers.length <= 1 ? (
                            <div></div>
                        ) : (
                            <li><a a onClick={() => props.paginate(pageNumbers.length)} href="#"><FontAwesomeIcon icon={faAngleDoubleRight}></FontAwesomeIcon></a></li>
                        )
                    }
                    
                </ul>
            </div>
        </div>
    </div>
  );
};

