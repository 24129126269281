import React, { useEffect, useState } from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import Footer from "../../Components/Footer"
import NavBar from "../../Components/NavBar"

import { faR, faClock, faVideo } from '@fortawesome/free-solid-svg-icons'

import indexImage from '../../assets/index.jpg'
import milestonesImage from '../../assets/milestones.jpg'

import style from './style.module.css'

import { Parallax } from "react-parallax"
import CountUp from "react-countup"

import classNames from "classnames";
import SearchBar from "../../Components/SearchBar";

import api from "../../service/api";

import { formatDate } from "../../Utils";

export default function Home(){
	const [streamedminutes, setStreamedMinutes] = useState(0)
	const [analysedVideos, setAnalysedVideos] = useState(0)
	const [latestClassifications, setLatestClassifications] = useState([])

	async function getStreamedMinutes(){
		const response = await api.get("/song/get/streamedminutes")
        .then(message => {
			setStreamedMinutes(message.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.request.data)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error);
              }
        })    
	}

	
	async function getAnalysedVideos(){
		const response = await api.get("/song/get/analysed/videos")
        .then(message => {
			setAnalysedVideos(message.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.request.data)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error);
              }
        })    
	}

	async function getLatestClassifications(){
		const response = await api.get("/song/get/latest/classifications")
		.then(message => {
			console.log(message.data)
			setLatestClassifications(message.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.request.data)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error);
              }
        })  
	}

	useEffect(() => {
		getStreamedMinutes()
		getAnalysedVideos()
		getLatestClassifications()
		
	}, [])

    return(
        <>
            {/*Loading Bar*/}
            <div className="super_container">
                <NavBar active = {1} ></NavBar>

            <div className={style.home}>
			    <div className={style.background_image} style={{backgroundImage: "url("+indexImage+")"}}></div>
			    {/*Title to help the user use the searchbar*/}
				<div className={style.home_container}>
					<div className={style.home_title}>
						<h1>Which song do you want to explore <span  className={style.highlight_word}>emotionally</span>?</h1>
					</div>

					{/*SearchBar */}
					<SearchBar></SearchBar>
				</div>

			    
		    </div>

            <div className={style.intro}>
				<div className="container">
					<div className="row">
						<div className="col">
							<div className={classNames(style.intro_content, "text-center")}>
								<div className={classNames(style.section_title, "text-center")}>
									<h1>About MER<span>maid</span></h1>
								</div>
								<div className={classNames(style.intro_text, "text-center")}>
									<p>MERmaid is a Music Emotion Recognition (MER) application from the <a href="http://mir.dei.uc.pt/" target="_blank">MIR.CISUC team</a>, part of the MERGE research project (<a href="https://doi.org/10.54499/PTDC/CCI-COM/3171/2021" target="_blank">PTDC/CCI-COM/3171/2021</a>). The system has been developed over the last years in a collaboration between <a href="https://www.cisuc.uc.pt/" target="_blank">CISUC</a> (emotion recognition) and <a href="http://ci2.ipt.pt/" target="_blank">Ci2.ipt</a> (software engineering part), with the purpose of illustrating the advances on this research topic, which usually stay buried in academic papers and do not reach the general public.</p>
								</div>
								<div className={classNames(style.button_fill, style.intro_button)}><a href="about">read more</a></div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Parallax className="parallax_background parallax-window" bgImage={milestonesImage} speed= {0.8}>
				<div className={classNames("container", style.milestones)}>
					<div className="row">
						<div className="col">
							<div className={classNames(style.section_title, style.light , "text-center")}>
								<h1>Fun Facts</h1>
							</div>
						</div>
					</div>

					<div className={classNames("row", style.milestones_row)}>
						<div className="col-xl-6 col-md-12">
							<div className={classNames(style.milestone ,"text-center")}>
								<div className={classNames(style.milestone_content , "ml-auto mr-auto")}>
									<CountUp  className={style.milestone_counter} end={streamedminutes}></CountUp>
									<div className={style.milestone_icon}><FontAwesomeIcon icon={faClock} size="6x"/></div>
								</div>
								<div className={style.milestone_title}>Streamed Minutes</div>
							</div>
						</div>
						<div className="col-xl-6 col-md-12">
							<div className={classNames(style.milestone, "text-center")}>
								<div className={classNames(style.milestone_content , "ml-auto mr-auto")}>
								<CountUp  className={style.milestone_counter} end={analysedVideos}></CountUp>
									<div className={style.milestone_icon}><FontAwesomeIcon icon={faVideo} size="6x"/></div>
								</div>
								<div className={style.milestone_title}>Analyzed Videos</div>
							</div>
						</div>	
					</div>
				</div>
			</Parallax>


			<div className={style.shows}>
				<div className="container">
					<div className="row">
						<div className="col">
							<div className={style.shows_title}>Latest Classifications</div>
						</div>
					</div>
					<div className={classNames("row",  style.shows_row)}>
						{
							latestClassifications.map(song => (
								<div className="col-lg-4">
									<div className={style.show}>
										<div className={style.show_image}>
											<a href={`/music/${song.external_id}`}>
												<img src={song.thumbnailHQ} alt="music video"/>
											</a>
											<div className={style.show_tags}>
												<div className={style.tags}>
													<ul className="d-flex flex-row align-items-start justify-content-start">
														<li><a href={`/browse?emotion=${song.general_classification}`}>{song.general_classification}</a></li>
													</ul>
												</div>
											</div>
										</div>
										<div className={style.show_content}>
											<div className={style.show_date}>{`${song.artist} on ${formatDate(new Date(song.date))}`}</div>
											<div className={style.show_title}><a href={`/music/${song.external_id}`}>{song.title}</a></div>
										</div>
									</div>
								</div>
							))
						}

					</div>
					<div className="row">
						<div className="col text-center">
							<div className="button_fill shows_2_button"><a href="/browse">browse more</a></div>
						</div>
					</div>
				</div>
			</div>

            <Footer></Footer>
        {/**/}
        </div>
        </>
    )
}