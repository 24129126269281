import React, { useEffect, useState } from "react";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Parallax} from 'react-parallax'
import Footer from "../../Components/Footer";
import NavBar from "../../Components/NavBar";
import queueImage from "../../assets/queue.jpg"

import style from "./style.module.css"
import classNames from "classnames";
import Pagination from "../../Components/Pagination";

import api from "../../service/api";

import loading from "../../assets/loading.svg"
import ProcessingSong from "../../Components/ProcessingSong";

import io from 'socket.io-client'

export default function Queue(){

	const [userId, setUserId] = useState(0)
	const [userIp, setUserIp] = useState(null)
	const [songs, setSongs] = useState([])
	const [isLoading, setisLoading] = useState(true)


	useEffect(() => {
		if(localStorage.getItem("token")){
			//Get the songs that are in the queue 
			getUserID()
		}else{
			getInQueueSongsByIp()
		}
	}, [])

	async function getInQueueSongsByIp(){
		const response = await api.get(`/songbyip`)
        .then(message => {
			console.log(message.data)
            setSongs(message.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.request.data)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error);
              }
        }) 
		
		setisLoading(false)
	}

	//get user information 
	async function getUserID(){
		var token = localStorage.getItem("token")
	
		const response = await api.get("/user", {
			headers: { Authorization: `Bearer ${token}`}
		})
			.then(async message => {
				const response = await api.get(`/song/getqueuesongs/${message.data.email}`)
						.then(message => {
							setSongs(message.data)
						}).catch(error => {
							if (error.response) {
								console.log(error.request.data)
							} else if (error.request) {
								console.log(error.request);
							} else {
								console.log('Error', error);
							}
						})   
			}).catch(error => {
				
		})  
		
		setisLoading(false)
	}

	if (isLoading){
        return(
            <div>
                <NavBar scrolled={true}></NavBar>
                <div className={style.Loading}>
                    <img src={loading}></img>
                </div>
            </div>
        )
    }

    return(
        <>
            {/*Loading Bar*/}
            <div className="super_container">
                <NavBar active = {2}></NavBar>

				<Parallax className="parallax_background parallax-window" bgImage={queueImage} speed={0.8}>
					<div className={style.home}>
						<div className={classNames(style.home_container, "d-flex flex-column align-items-center justify-content-center")}>
							<div className={style.home_content}>
								<div className={style.home_title}>
									<h1>processing queue</h1>
								</div>
							</div>
						</div>
					</div>
				</Parallax>

				<div className={style.blog}>
					<div className="container">
						<div className="row">
							<div className={style.blog_posts}>
								{/* Loading the songs that are in the queue */}
								{
									songs.map(song => {
										return(
											<ProcessingSong song = {song}></ProcessingSong>
										)
									})
								}
							</div>
						</div>
					</div>
				</div>
        	</div>

			<Footer></Footer>
        </>
    )
}