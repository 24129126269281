import './App.css';
import React, { Component }  from 'react';
import Router from './router';

import { io } from 'socket.io-client';

import {SocketProvider} from "./Context/SocketContext"


function App() {
  return (
    <SocketProvider>
        <Router></Router>
    </SocketProvider>
    
  );
}

export default App;
