import React from "react";

import Footer from "../../Components/Footer"
import NavBar from "../../Components/NavBar"

import indexImage from '../../assets/index.jpg'
import notFound from '../../assets/notfound404.svg'

import style from './style.module.css'


export default function NotFound404(){
    return(
        <> 
        <div className={style.body}>
            <NavBar scrolled={true}></NavBar>
            <div className={style.Error}>
                <div className={style.image}>
                    <img src={notFound}></img>
                </div>
                <div className={style.backHome}>
                    <a href="/">Go back home</a>
                </div>
            </div>
        </div>
        </>
    )
}