import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../../Components/NavBar"
import { Audio } from 'react-loader-spinner'

import api from "../../service/api";

import loading from "../../assets/loading.svg"
import loadingWhite from "../../assets/loading_white.svg"
import expiredEmail from "../../assets/expiredemail.svg"
import warning from "../../assets/warning.svg"
import verified from "../../assets/verified.svg"

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import style from "./style.module.css"

export default function ResetPassw(){
    const {token} = useParams()

    const navigation = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [cpassword, setcPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState(null)
    const [userv, setUserV] = useState(false)

    async function resetPassw(){
        if(!(password === cpassword)){
            alert("The passwords dont match, please try again")
            return
        }else{
            const response = await api.post("/user/change/reset/password", {
                email: email, 
                password: password
            })
            .then(message => {
                alert("Your password has been successfully reset")
            }).catch(error => {
                if (error.response) {
                    alert(error.response.data)
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            }) 
        }

    }

    async function verifyUser(){
        //First we have to see if the token is valid 
        //That means if the token is for a existent user on the database or is expired
        const userData = await api.get("/user", {
            headers: { Authorization: `Bearer ${token}`}
        })
            .then(async message => {
                setEmail(message.data.email)
                await confirmToken()
            }).catch(error => {
                if (error.response) {
                    setErrorMessage(error.response.data.error)
                    console.log(error.response.data.error)
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
            })        
    }

    async function confirmToken(){
        const response = await api.get(`/confirm/${token}`)
        .then(message => {
            setUserV(true)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
        })    
    }

    useEffect(() => {
        verifyUser()
    }, [])
    return(
        <>
        <div className={style.body}>
            <NavBar scrolled = {true}></NavBar>

            {
                errorMessage === "Invalid token!" ? (
                    <div className={style.InvalidToken}>
                        <div className={style.InvalidToken_img}>
                            <img src={warning}></img>
                        </div>
                        <div className={style.InvalidToken_txt}>
                            We're sorry, but the token you are using to reset your password is invalid. 
                        </div>
                    </div>
                ) : (
                    //Now if the token has expired we have to tell the user to ask for another email so he can confirm again the email
                    errorMessage == "Token expired!" ? (
                        <div className={style.expiredEmail}>
                            <div className={style.expiredEmail_img}>
                                <img src={expiredEmail}></img>
                            </div>
                            <div className={style.expiredEmail_txt}>
                             We're sorry, but the token you are using to reset your password has expired. Please request a new password reset link.
                            </div>
                            
                        </div>
                ) : (
                    <div className={style.form}>
                        <form className={style.signUpForm} onSubmit={resetPassw}>
                            <label htmlFor="password">New Password*</label>
                            <input type={"password"} required id="password" onChange={event => setPassword(event.target.value)}></input>
                            
                            <label htmlFor="cpassword">Confirm Password*</label>
                            <input type={"password"} id="cpassword" required onChange={event => setcPassword(event.target.value)}></input>


                            <button type="submit">Reset Password</button>
                        </form>
                    </div>
                )
            )
            }
           
        </div>   
    </>
    )
}